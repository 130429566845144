<template>
  <div class="content">
    <div class="block block-rounded">
      <div class="block-header block-header-default">
        <h3 class="block-title">
          <i class="fa fa-user-circle me-1 text-muted"></i> 个人资料
        </h3>
      </div>
      <div class="block-content">
        <form id="edit-profile" onsubmit="return false;">
          <div class="row items-push">
            <div class="col-lg-3">
              <p class="text-muted">你可以在这里修改你的用户信息</p>
            </div>
            <div class="col-lg-7 offset-lg-1">
              <div class="mb-4">
                <label class="form-label">账号</label>
                <input
                  class="form-control form-control-lg"
                  id="username"
                  name="username"
                  placeholder="Enter your username.."
                  readonly=""
                  type="text"
                  v-model="user.username"
                />
              </div>
              <div class="mb-4">
                <label class="form-label">昵称</label>
                <input
                  class="form-control form-control-lg"
                  id="nickname"
                  name="nickname"
                  placeholder="Enter your NICKNAME.."
                  type="text"
                  v-model="user.nickname"
                />
              </div>
              <div class="mb-4">
                <label class="form-label">QQ</label>
                <input
                  class="form-control form-control-lg"
                  id="qq"
                  name="qq"
                  placeholder="Enter your QQ.."
                  type="number"
                  v-model="user.qq"
                />
              </div>
              <div class="mb-4">
                <label class="form-label">邮箱</label>
                <input
                  class="form-control form-control-lg"
                  id="mail"
                  name="mail"
                  placeholder="Enter your mail.."
                  type="email"
                  v-model="user.email"
                />
              </div>
              <div class="mb-4">
                <button
                  class="btn btn-alt-primary"
                  @click="update_info()"
                  type="submit"
                >
                  更新
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="block block-rounded">
      <div class="block-header block-header-default">
        <h3 class="block-title">
          <i class="fa fa-share-alt me-1 text-muted"></i> 快捷登录
        </h3>
      </div>
      <div class="block-content">
        <form onsubmit="return false;">
          <div class="row items-push">
            <div class="col-lg-3">
              <p class="text-muted">你可以在这里绑定快捷登录方式</p>
            </div>
            <div class="col-lg-7 offset-lg-1">
              <!-- 已绑定QQ快捷登录 -->
              <div class="row mb-4" v-if="user.openId">
                <div class="col-sm-10 col-md-8 col-xl-6">
                  <button
                    class="btn w-100 text-start btn-alt-info bg-white"
                    disabled
                  >
                    <i class="fa fab fa-qq me-1"></i> 已绑定QQ快捷登录
                  </button>
                </div>
                <div
                  class="col-sm-12 col-md-4 col-xl-6 mt-1 d-md-flex align-items-md-center"
                >
                  <a class="text-muted" @click="unset_QQ()">
                    <i class="fa fa-unlink me-1"></i> 解绑
                  </a>
                </div>
              </div>
              <div class="row mb-4" v-else>
                <div class="col-sm-10 col-md-8 col-xl-6">
                  <button
                    class="btn w-100 text-start btn-alt-primary"
                    @click="bindQQ()"
                    type="submit"
                  >
                    <i class="fab fa-qq me-1"></i> 绑定QQ快捷登录
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="block block-rounded">
      <div class="block-header block-header-default">
        <h3 class="block-title">
          <i class="fa fa-asterisk me-1 text-muted"></i> 修改密码
        </h3>
      </div>
      <div class="block-content">
        <form id="change_PassWord" onsubmit="return false;">
          <div class="row items-push">
            <div class="col-lg-3">
              <p class="text-muted">你可以在这里修改登录密码</p>
            </div>
            <div class="col-lg-7 offset-lg-1">
              <div class="mb-4">
                <label class="form-label" prop="password">当前密码</label>
                <input
                  class="form-control form-control-lg"
                  v-model="pwd.password"
                  type="text"
                />
              </div>
              <div class="mb-4">
                <label class="form-label" prop="newpassword">新密码</label>
                <input
                  class="form-control form-control-lg"
                  v-model="pwd.newPassword"
                  type="text"
                />
              </div>
              <div class="mb-4">
                <label class="form-label">确认新密码</label>
                <input
                  class="form-control form-control-lg"
                  v-model="pwd.confirmPassword"
                  type="text"
                />
              </div>
              <div class="mb-4">
                <button
                  class="btn btn-alt-primary"
                  @click="update_pwd()"
                  type="submit"
                >
                  修改
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { updateUser, updatePwd, bindQQ } from "@/api/user";
export default {
  name: "User",
  inject: ["reloadUser"],
  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      pwd: {},
    };
  },

  methods: {
    update_info() {
      updateUser(this.user)
        .then((msg) => {
          this.$message.success(msg);
          this.reloadUser();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    update_pwd() {
      if (this.pwd.newPassword !== this.pwd.confirmPassword) {
        this.$message.warning("2次输入的新密码不相同");
        return false;
      }
      updatePwd(this.pwd)
        .then((msg) => {
          this.$message.success(msg);
          this.$store.commit("logout");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    bindQQ() {
      bindQQ().then((res) => {
        window.location.href = res.data;
      });
    },
    unset_QQ() {},
  },
};
</script>

<style scoped></style>
