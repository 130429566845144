import request from "@/utils/request";

/**
 * 获取用户
 *
 */
export async function getUser() {
  const res = await request.get("/user");
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 修改用户
 * @param data 用户信息
 */
export async function updateUser(data) {
  const res = await request.put("/user", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 修改用户密码
 * @param data 用户信息
 */
export async function updatePwd(data) {
  const res = await request.put("/user/password", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}
/**
 * 绑定qq
 */
export async function bindQQ(data) {
  const res = await request.get("/oauth/bind", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 注销登录
 */
export async function logout() {
  const res = await request.delete("/user/logout");
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 使用卡密充值
 */
export async function activate(data) {
  const res = await request.post("/user/activate", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 购买商品
 */
export async function buy(data) {
  const res = await request.post("/user/buy", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}
